@import url(./Font.css);

html,
body,
#root {
  background-color: #fbfbfb;
  height: 100%;
}

.contentWrapper {
  padding: 1rem;
  background-color: #fbfbfb;
}

.header {
  background-color: #70aead;
  padding: 1rem;
  padding-bottom: 0.5rem;
}

#scrollContainer {
  overflow: scroll;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.standardShadow {
  box-shadow: 3px 3px 4px 1px rgba(0, 0, 0, 0.21);
}

.standardRadius {
  border-radius: 4px;
}

.ActionNavbarDesktop {
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  gap: 1rem;
}

.ActionNavbarMobile {
  position: sticky;
  justify-content: space-around;
  padding-bottom: 0.5rem;
  bottom: 0;
}

.ShortEllipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 100%;
}

@media (min-width: 900px) {
  .contentWrapper,
  .header {
    padding: 2rem 4rem;
  }
  .header {
    padding-bottom: 0;
  }
}

@media (min-width: 1080px) {
  .header {
    padding-bottom: 0;
  }
}

.showIsDroppable {
  box-shadow: 0 0 0 3px #ccc inset;
}
.highlightAsCurrentTarget {
  box-shadow: 0 0 0 3px #03927a inset;
}

#CookiebotWidget {
  right: 10px !important;
  left: revert !important;
}
